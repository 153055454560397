<template>
  <div class="create-work-material-block">
    <menu-breadcrumb>
      <el-breadcrumb-item :to="{ name: 'Work' }">作品管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ name: 'WorkMaterial' }"
        >媒材管理</el-breadcrumb-item
      >
      <el-breadcrumb-item>創建媒材</el-breadcrumb-item>
    </menu-breadcrumb>

    <div class="container">
      <el-form
        ref="workMaterialForm"
        :model="work_material_form"
        :rules="work_material_rules"
        label-position="top"
      >
        <el-form-item label="中文媒材名稱" prop="name_zh">
          <el-input v-model="work_material_form.name_zh"></el-input>
        </el-form-item>
        <el-form-item label="英文媒材名稱" prop="name_en">
          <el-input v-model="work_material_form.name_en"></el-input>
        </el-form-item>
        <el-form-item>
          <div class="flex-end">
            <el-button type="warning" @click="handleRedirect">回主頁</el-button>
            <el-button type="success" @click="handleSubmit">送出</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { createWorkMaterial } from "@/api/work-material";

export default {
  name: "CreateWorkMaterial",
  data() {
    return {
      work_material_form: {
        name_zh: "",
        name_en: "",
      },
      work_material_rules: {
        name_zh: [{ required: true, message: "請輸入媒材", trigger: "blur" }],
        name_en: [{ required: true, message: "請輸入媒材", trigger: "blur" }],
      },
    };
  },
  methods: {
    handleSubmit() {
      this.$refs.workMaterialForm.validate(async (valid) => {
        if (valid) {
          await createWorkMaterial(this.work_material_form);

          this.$message({
            type: "success",
            message: "創建成功",
          });

          await this.handleRedirect();
        }
      });
    },
    async handleRedirect() {
      await this.$router.push({ name: "WorkMaterial" });
    },
  },
};
</script>

<style lang="scss" scoped></style>
