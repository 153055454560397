import request from "@/utils/request";

/**
 * Get work materials with search feature.
 *
 * @param {object} [params]
 * @param {string} params.search
 */
export function getWorkMaterials(params) {
  return request({
    url: "api/v1/works-materials",
    method: "GET",
    params,
  });
}

/**
 * Get work materials with pagination feature.
 *
 * @param {object} [params]
 * @param {number} params.limit
 * @param {number} params.page
 */
export function getWorkMaterialsPagination(params) {
  return request({
    url: "api/v1/works-materials/page",
    method: "GET",
    params,
  });
}

/**
 * Get work material.
 *
 * @param {number} work_material_id
 */
export function getWorkMaterial(work_material_id) {
  return request({
    url: `api/v1/works-materials/${work_material_id}`,
    method: "GET",
  });
}

/**
 * Create work material.
 *
 * @param {object} data
 * @param {string} data.name_zh
 * @param {string} data.name_en
 */
export function createWorkMaterial(data) {
  return request({
    url: "api/v1/works-materials",
    method: "POST",
    data,
  });
}

/**
 * Update work material.
 *
 * @param {number} work_material_id
 * @param {object} data
 * @param {string} data.name_zh
 * @param {string} data.name_en
 */
export function updateWorkMaterial(work_material_id, data) {
  return request({
    url: `api/v1/works-materials/${work_material_id}`,
    method: "PATCH",
    data,
  });
}

/**
 * Delete work material.
 *
 * @param {number} work_material_id
 */
export function deleteWorkMaterial(work_material_id) {
  return request({
    url: `api/v1/works-materials/${work_material_id}`,
    method: "DELETE",
  });
}
